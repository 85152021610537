import React from 'react';
import { Link } from "gatsby";
import Logo from '../assets/images/Logo.svg'

const Nav = (props) => {
  // console.log(props.isOpen);                                     
  return(
    <nav className={`st-menu st-effect-1 ${props.isOpen ? 'st-menu-open': null}`} id="menu-1">
      <h2 className="icon icon-lab">
        <img
          src={Logo}
          className="logo-image"
          alt="logo"
        />
      </h2>
      <ul id="menu-main" className="navbar-nav ml-auto text-white">
        <li
          id="menu-item-127"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-127 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/services/">
            Services
          </Link>
        </li>
        <li
          id="menu-item-130"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-130 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/about-us/">
            About
          </Link>
        </li>
        <li
          id="menu-item-129"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-129 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/contact-us/">
            Contact
          </Link>
        </li>
        <li
          id="menu-item-582"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-582 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/our-projects/">
            Work
          </Link>
        </li>
        <li
          id="menu-item-851"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-851 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/our-tools/">
            Tools
          </Link>
        </li>
        <li
          id="menu-item-128"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-128 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/blogs/">
            Blog
          </Link>
        </li>
        {/* <li
          id="menu-item-128"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-128 nav-item px-3 font-12px"
        >
          <Link className="nav-link" to="/videos">
            Video's
          </Link>
        </li> */}
      </ul>
      <p className="contact-me secondary-font-heavy mt-5">
        <strong>Contact us</strong>
      </p>
      <hr align="left" width="10%" style={{ marginLeft: "17px" }} />
      <p className="contact-me">
        <strong>E</strong>: team@alkye.com
      </p>
      <p className="contact-me">
        <strong>P</strong>: +61-0436 283 666
      </p>

      <hr align="left" width="10%" style={{ marginLeft: "17px" }} />

      <p className="contact-me">© 2020 Alkye Services Pty Ltd.</p>
    </nav>
  )
}

export default Nav;