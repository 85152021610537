import React, {useState, useEffect} from 'react';
import { Link } from "gatsby";
import Logo from '../assets/images/Light.svg'; 

const Footer = () => {
  const [scrollToTop, setScrollToTop] = useState(false);
  const [scroll, setScroll] = useState(false);
  
  useEffect(() => {
    if (scrollToTop) {
      setScrollToTop(false);
      try {
        if (typeof window !== 'undefined') {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      } catch (error) {
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
      }
    }
  }, [scrollToTop, setScrollToTop]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 200);
      });
    }
  }, []);

  return(
      <>
        <div className="container-fluid footer-container">
          <div className="row p-0 m-0">
            <div className="container main-container-padding-t-5 main-container-padding-b-5">
              <div className="row">
                <div className="col-md-6 mt-2">
                  <img
                    src={Logo}
                    className="logo-image"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 mt-2 text-md-right">
                  <div className="header-text-container">
                    <div className="vertical-center">
                      <Link to="/contact-us" className="button-primary">
                        <div className="row no-padding">
                          <div className="col-md-11 col-9 text-md-right">
                            <h2>Get in touch&nbsp;&nbsp;</h2>
                            <p className="smaller-text">We're here for you. Say hi.</p>
                          </div>
                          <div className="col-md-1 col-3 p-md-3 p-2">
                            <span>
                              <i className="la la-angle-right"></i>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row main-container-padding-t-5">
                <div className="col-md-6">
                  <Link to="/privacy-policy/" className="link-primary">
                    Privacy Policy
                  </Link>
                  <br />
                  <Link to="/terms-conditions/" className="link-primary">
                    Terms &amp; Conditions
                  </Link>
                  <br />
                  <br />
                  <p className="link-primary">
                    © {new Date().getFullYear()} Alkye Services Ltd | Alkye Services LLC
                  </p>
                </div>
                <div className="col-md-6 text-right text-white">
                  <div className="header-text-container">
                    <a
                      href="https://www.facebook.com/alkyetech/"
                      className="link-primary social-icons"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="la la-facebook-official"></i>
                    </a>
                    <a href="https://www.pinterest.com.au/Alkyeservices/_created/" className="link-primary social-icons" target="_blank" rel="noreferrer">
                      <i className="la la-pinterest"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/alkye"
                      className="link-primary social-icons"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="la la-linkedin"></i>
                    </a>
                    <a
                      href="https://twitter.com/alkyetech"
                      className="link-primary social-icons"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="la la-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button onClick={() => setScrollToTop(true)} className={`backtop ${scroll ? "active" : ""}`}><i className="la la-angle-right"></i></button>
      </>
  )
}

export default Footer