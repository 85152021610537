import React from 'react';

import Header from './Header';
import Footer from './Footer';
import MobileNav from './MobileNav';

const PrimaryLayout = props => (
  <div className="st-container st-effect-1">
    <MobileNav />

    <div className="st-pusher" id="st-pusher">
      <div className="st-content" id="st-content">
        <Header />

        {props.children}

        <Footer />
      </div>
    </div>
  </div>
)

export default PrimaryLayout;