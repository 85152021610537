import React, { useState, useLayoutEffect } from "react";
import { Link } from "gatsby";
import LogoLight from '../assets/images/Light.svg';
import LogoColor from '../assets/images/LogoColored.svg';
import MobileNav from './MobileNav';

export default function Header(){
  const [isActive, setActive] = useState(false);
  
  // const [bodyOffset, setBodyOffset] = useState(
  //   document.body.getBoundingClientRect()
  // );
  // const [scrollY, setScrollY] = useState(bodyOffset.top);

  // const listener = e => {
  //   setBodyOffset(document.body.getBoundingClientRect());
  //   setScrollY(-bodyOffset.top);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", listener);
  //   return () => {
  //     window.removeEventListener("scroll", listener);
  //   };
  // });

  const [scrollPosition, setPosition] = useState(0);
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
    
  return(
    <>
      <div className="header hero-bg">
        <div className={`container main-menu-mobile clearHeader ${scrollPosition > 120 ? 'c-logo--scrolled' : ''}`} id="mobileHeader">
          <nav className="navbar navbar-expand-sm navbar-transparent text-white  text-lowercase">
            <div className="navigation-row">
              <Link className="navbar-brand ml-1 main-logo" to="/">
                {scrollPosition > 120 ? (
                  <img
                    src={LogoColor}
                    className="logo-image"
                    id="logo-image"
                    alt="logo"
                  />
                ) : (
                  <img
                    src={LogoLight}
                    className="logo-image"
                    id="logo-image"
                    alt="logo"
                  />
                )}
                <span className="logo-text"></span>
              </Link>
              <div className="float-right mt-2">
                <div
                  id="st-trigger-effects"
                  className="column"
                  style={{ display: isActive ? "none": "block" }}
                >
                  <button
                    className="d-lg-none"
                    type="button"
                    data-effect="st-effect-1"
                    onClick={() => setActive(!isActive)}
                    style={{
                      border: "1px solid #ffffff",
                      backgroundColor: "#000000",
                    }}
                  >
                    <i
                      className="la la-bars"
                      style={{
                        color: "#ffffff",
                        padding: "15px 4px",
                        backgroundColor: "#000000",
                      }}
                    ></i>
                  </button>
                </div>
                <div
                  className="column"
                  id="crossIcon"
                  style={{
                    display: isActive ? "block": "none",
                    border: "1px solid rgb(255, 255, 255)",
                    backgroundColor: "rgb(0, 0, 0)",
                  }}
                >
                  <button
                    className=" d-lg-none"
                    type="button"
                    onClick={() => setActive(!isActive)}
                    style={{ backgroundColor: "#000000" }}
                  >
                    <i
                      className="la la-times"
                      style={{
                        color: "#ffffff",
                        padding: "15px 4px",
                        backgroundColor: "#000000",
                      }}
                    ></i>
                  </button>
                </div>

                <div className="text-white d-none d-md-block" id="collapsibleNavId">
                  <ul id="menu-main-1" className="navbar-nav ml-auto text-white">
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-127 nav-item px-3 font-12px">
                      <Link className="nav-link" to="/services/">
                        Services
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-130 nav-item px-3 font-12px">
                      <Link className="nav-link" to="/about-us/">
                        About
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-129 nav-item px-3 font-12px">
                      <a className="nav-link" href="/contact-us/">
                        Contact
                      </a>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-582 nav-item px-3 font-12px">
                      <Link
                        className="nav-link"
                        to="/our-projects/"
                      >
                        Work
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-851 nav-item px-3 font-12px">
                      <Link className="nav-link" to="/our-tools/">
                        Tools
                      </Link>
                    </li>
                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-128 nav-item px-3 font-12px">
                      <Link className="nav-link" to="/blogs/">
                        Blog
                      </Link>

                      {/* <ul>
                        <li>
                          <Link className="nav-link" to="/videos">
                            Video's
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <MobileNav isOpen={isActive} />
    </>
  )
}